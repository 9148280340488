import "../scss/main.scss";
import LocomotiveScroll from 'locomotive-scroll';
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Swiper, {Navigation, EffectFade, Pagination, Thumbs} from 'swiper';

Swiper.use([Navigation, EffectFade, Pagination, Thumbs]);

(function () {
    document.addEventListener('DOMContentLoaded', function () {
        const htmlCont = document.querySelector('html');
        const bodyCont = document.querySelector('body');

        const scroller = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            scrollbarContainer: htmlCont,
        });
        scroller.on('scroll', ScrollTrigger.update)
        const mainContentBlock = document.querySelector('.main-content');
        const openMenuBtn = document.querySelector('#open-menu-btn');
        const openMenuBtnFacts = document.querySelector('#open-menu-btn-facts');
        const menuCont = document.querySelector('.menu-items.no-modal');
        const menuContFacts = document.querySelector('.menu-items.modal');
        const firstScreenBg = document.querySelector('.first-screen-bg.hide-576');
        const navMenuBtn = document.querySelectorAll('.select-menu-item');
        let img1Cont = document.querySelector('.professional-first-line .img-container');
        let img1 = document.querySelector('.professional-first-line img');
        let img2Cont = document.querySelector('.professional-second-line .img-container');
        let img2 = document.querySelector('.professional-second-line img');
        let img3 = document.querySelector('.professional-third-line img');

        if (navMenuBtn) {
            navMenuBtn.forEach(i => {
                i.addEventListener('click', function (e) {
                    menuCont.classList.toggle('open-byu-select');
                    menuContFacts.classList.toggle('open-byu-select');
                })
            })

        }

        if (openMenuBtn) {
            openMenuBtn.addEventListener('click', function (e) {
                menuCont.classList.add('open');
            })
        }
        if (openMenuBtnFacts) {
            openMenuBtnFacts.addEventListener('click', function (e) {
                menuContFacts.classList.add('open');
            })
        }
        const closeMenuBtn = document.querySelector('#close-menu-btn');
        if (closeMenuBtn) {
            closeMenuBtn.addEventListener('click', function (e) {
                menuCont.classList.remove('open');
            })
        }
        const closeMenuBtnFacts = document.querySelector('#close-menu-btn-facts');
        if (closeMenuBtnFacts) {
            closeMenuBtnFacts.addEventListener('click', function (e) {
                menuContFacts.classList.remove('open');
            })
        }


        const options = {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '0px',
            threshold: 1,
        }
        const options4 = {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '100px',
            threshold: 1,
        }
        const options5 = {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '200px',
            threshold: 1,
        }
        const options2 = {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '-100px',
            threshold: 0.5,
        }
        const options3 = {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '0px',
            threshold: 0.5,
        }

        // создаем наблюдатель для первого экрана
        const observer = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    let showElems = entry.target.querySelectorAll(':scope > div');
                    showElems.forEach(i => {
                        i.parentElement.style = 'overflow: hidden;';
                        i.style = 'transform: translate(0px, 0px);';
                    })

                }
            })
        }, options);

        // появление текста на первом экране
        const textDownTop = document.querySelectorAll('.line-down-top-show');
        // Незаконченный элемент
        textDownTop.forEach(i => {
            i.style = 'transform: translate(0px, 0px);';
        })
        // появление текста в блоке "открой границы"
        firstScreenBg.onload = function () {

        }

        const openBorderTitles = document.querySelectorAll('.titles-block');
        openBorderTitles.style = 'overflow: hidden;';
        openBorderTitles.forEach(i => {
            observer.observe(i)
        })

        // создаем наблюдатель observerOpenBorder
        const observerOpenBorder = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {

                    let borderElem = entry.target.querySelector('.line-delimiter .line-delimiter-bord');
                    borderElem.style = 'height: 100%';
                    let textElem = entry.target.querySelectorAll('.cell-text');
                    textElem.forEach(i => {
                        i.style = 'transform: translate(0%, 0px);';
                    })
                }
            })
        }, options4)

        // появление контента в блоке "открой границы"
        const openBorderContent = document.querySelectorAll('.open-borders-line');
        openBorderContent.forEach(i => {
            observerOpenBorder.observe(i)
        })

        let menu = ['Espresso Forte', 'Gusto Classico', 'Arabica Densa', 'Crema  Vellutata']

        // Карусель заголовков блока Коллекции
        const swiperTitles = new Swiper('.collection-slider-titles.swiper', {
            direction: "horizontal",
            loop: true,

            effect: 'slide',
            slidesPerView: 1,
            speed: 2000,
            watchSlidesProgress: true,
            allowTouchMove: false,
        });

        // Карусель блока Коллекции
        const swiper = new Swiper('.collection-slides.swiper', {
            direction: "horizontal",
            loop: true,
            slidesPerView: 1,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            speed: 2000,
            navigation: {
                nextEl: '.collection-slider-next',
                prevEl: '.collection-slider-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (menu[index]) + '</span>';
                },
            },
            thumbs: {
                swiper: swiperTitles,
            },
        });

        swiper.on('slideChange', function () {
            if (this.visibleSlides[0].classList.contains('pink-slide')) {
                this.navigation.nextEl.classList.add('pink-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.add('pink-swiper-arrow')
                }
            } else {
                this.navigation.nextEl.classList.remove('pink-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.remove('pink-swiper-arrow')
                }
            }
            if (this.visibleSlides[0].classList.contains('brow-slide')) {
                this.navigation.nextEl.classList.add('brow-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.add('brow-swiper-arrow')
                }
            } else {
                this.navigation.nextEl.classList.remove('brow-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.remove('brow-swiper-arrow')
                }
            }
            if (this.visibleSlides[0].classList.contains('yellow-slide')) {
                this.navigation.nextEl.classList.add('yellow-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.add('yellow-swiper-arrow')
                }
            } else {
                this.navigation.nextEl.classList.remove('yellow-swiper-arrow')
                if (window.innerWidth < 768) {
                    this.navigation.prevEl.classList.remove('yellow-swiper-arrow')
                }
            }
        });

        document.querySelectorAll('.collection-slide').forEach(i => {
            i.addEventListener('click', function (e) {
                swiper.slideTo(this.dataset.collection);
                let target = document.querySelector('.collection-slider')
                scroller.scrollTo(target);
                // document.querySelector('.collection-slider').scrollIntoView({behavior: 'smooth', block: 'start'})
            })
        })

        // вращающийся по кругу текст в блоке коллекции
        const textsCircle = document.querySelectorAll(".circle-text.circle .text p");
        if (textsCircle) {
            textsCircle.forEach(text => {
                const elemscont = text.innerText.split("");
                text.innerHTML = elemscont.map((letter, i) =>
                    `<div style="transform:rotate(${i * (360 / elemscont.length)}deg)">${letter}</div>`
                ).join("");
                return;
            })
        }

        // анимация при скроле
        const animate = (element, position) => {
            // element.style.transform = `translateX(${position}px)`
            element.style = `transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${position}, 0, 0, 1);`
        }
        const professTitle1 = document.querySelector('.professional-title .first-line')
        const professTitleCont = gsap.utils.toArray('.professional-title')
        const professTitle2 = document.querySelector('.professional-title .second-line .line-text')
        const borderMidTitle = document.querySelector('.open-borders-subtitle .sub-title')
        const collectionBlock = document.querySelector('.collection-container')

        gsap.registerPlugin(ScrollTrigger)
        ScrollTrigger.scrollerProxy(
            '.scroll-containeer', {
                scrollTop(value) {
                    // return arguments.length ?
                    //     scroller.scrollTo(value, 0, 0) :
                    //     scroller.scroll.instance.scroll.y
                    return scroller.scroll.instance.scroll.y
                },
                getBoundingClientRect() {
                    return {
                        left: 0, top: 0,
                        width: window.innerWidth,
                        height: window.innerHeight
                    }
                }
            }
        )
        ScrollTrigger.addEventListener('refresh', () => scroller.update())
        ScrollTrigger.refresh();
        const professTitle1SPAN = document.querySelector('.professional-title .first-line span')
        const professTitle2SPAN = professTitle2.querySelector('span')
        if (window.innerWidth > 424) {
            var timelineText1 = gsap.to(professTitle1SPAN, {
                x: '0%',
            });
            const proffTrigger1 = ScrollTrigger.create({
                trigger: ".professional-title",
                scroller: '.scroll-containeer',
                start: "-300px center",
                animation: timelineText1,
            });
            var timelineText2 = gsap.to(professTitle2SPAN, {
                x: '0%',
            });
            const proffTrigger2 = ScrollTrigger.create({
                trigger: ".professional-title",
                scroller: '.scroll-containeer',
                start: "-300px center",
                animation: timelineText2,
            });
        }

        if (window.innerWidth < 425) {

            var timeline1SPAN = gsap.timeline({
                scrollTrigger: {
                    trigger: '.professional-title',
                    scroller: '.scroll-containeer',
                    start: '-600px 70%',
                    ease: "none",
                    scrub: true,
                    end: "400px 70%",
                    // markers: true,
                }
            })
            var timeline2SPAN = gsap.timeline({
                scrollTrigger: {
                    trigger: '.professional-title',
                    scroller: '.scroll-containeer',
                    start: '-600px 70%',
                    ease: "none",
                    scrub: true,
                    end: "400px 70%",
                    // markers: true,
                }
            })
            timeline1SPAN.from(
                professTitle1SPAN, {
                   x: '100%'
                }
            )
            timeline1SPAN.to(
                professTitle1SPAN, {
                x: '-50%'
            });
            timeline2SPAN.from(
                professTitle2SPAN, {
                    x: '100%'
                }
            )
            timeline2SPAN.to(
                professTitle2SPAN, {
                    x: '-50%'
                });
        }

        var timelineImg1 = gsap.timeline({
            scrollTrigger: {
                trigger: '.professional-first-line',
                scroller: '.scroll-containeer',
                start: 'top 70%',
                ease: "none",
                scrub: true,
                end: "+=100%",
            }
        })
        timelineImg1.from(
            img1, {
                y: '50',
                height: '60%',
            }
        )
        timelineImg1.to(img1, {
            y: '-100',
            height: '100%',
            ease: "none"
        });


        var timelineImg2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.professional-second-line',
                scroller: '.scroll-containeer',
                start: 'top 70%',
                ease: "none",
                scrub: true,
                end: "+=100%",
            }
        })
        timelineImg2.from(
            img2, {
                y: '70',
                height: '60%',
            }
        )
        timelineImg2.to(img2, {
            y: '-50',
            height: '100%',
            ease: "none"
        })

        var timelineImg3 = gsap.timeline({
            scrollTrigger: {
                trigger: '.professional-third-line',
                scroller: '.scroll-containeer',
                start: 'top 70%',
                ease: "none",
                scrub: true,
                end: "+=100%",
                // markers: true,
            }
        })
        timelineImg3.from(
            img3, {
                y: '50',
                height: '60%',
            }
        )
        timelineImg3.to(
            img3, {
                y: '-50',
                height: '100%',
                ease: "none",
            }
        )

        if (window.innerWidth > 768) {
            var timelineImg4 = gsap.to(borderMidTitle, {
                x: '0%',
            });
            const st = ScrollTrigger.create({
                trigger: ".open-borders-subtitle",
                scroller: '.scroll-containeer',
                start: "-400xp center",
                end: "+=500",
                animation: timelineImg4,
                // markers: true,
            });
        }

        // создаем наблюдатель collectionBlock
        const observercollectionBlock = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента

            entries.forEach(entry => {
                // console.log(entry);
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    entry.target.classList.add('show-collection-container');
                }
            })
        }, {
            // родитель целевого элемента - область просмотра
            // root: null,
            // без отступов
            rootMargin: '200px',
            threshold: 1,
        })

        observercollectionBlock.observe(collectionBlock)


        var throttle = function (type, name, obj) {
            var obj = obj || window;
            var running = false;
            var func = function () {
                if (running) {
                    return;
                }
                running = true;
                requestAnimationFrame(function () {
                    obj.dispatchEvent(new CustomEvent(name));
                    running = false;
                });
            };
            obj.addEventListener(type, func);
        };
        throttle("scroll", "optimizedScroll");

        window.addEventListener("optimizedScroll", function () {
            if (window.innerWidth < 769) {
                let lastKnownScrollPosition2 = borderMidTitle.getBoundingClientRect().y - 320;
                // if (lastKnownScrollPosition2 < 0) lastKnownScrollPosition2 = 0;
                borderMidTitle.style = `transform: translateX(${lastKnownScrollPosition2}px);`
            }
        })

        const professionalizbImg = document.querySelectorAll('.professional-block .professional-title');
        // создаем наблюдатель observerOpenBorder
        const observerProfImg = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    document.addEventListener('scroll2', function (e) {
                        let diapazon = img1.getBoundingClientRect().y
                        if (diapazon < 0) diapazon = 0
                        window.requestAnimationFrame(function () {
                            animateImg(img1Cont, (img1Cont.getBoundingClientRect().y + 100) * .2)
                            let prsentNum = (img1.getBoundingClientRect().y + 100) * .1;
                            if (window.innerWidth < 1024) {
                                prsentNum -= 30;
                            }
                            if (prsentNum < 0) prsentNum = 0;
                            if (prsentNum > 30) prsentNum = 30;
                            prsentNum = 100 - prsentNum;
                            img1.style.height = `${prsentNum}%`
                            animateImg(img2Cont, (img2Cont.getBoundingClientRect().y + 100) * .2)
                            prsentNum = (img2.getBoundingClientRect().y + 100) * .05;
                            if (prsentNum < 0) prsentNum = 0;
                            if (prsentNum > 20) prsentNum = 20;
                            prsentNum = 100 - prsentNum;
                            img2.style.height = `${prsentNum}%`
                            animateImg(img3, (img3.getBoundingClientRect().y + 100) * .2)
                            prsentNum = (img3.getBoundingClientRect().y + 100) * .1;
                            if (window.innerWidth < 1024) {
                                prsentNum -= 10;
                            }
                            if (prsentNum < 0) prsentNum = 0;
                            if (prsentNum > 30) prsentNum = 30;
                            prsentNum = 100 - prsentNum;
                            img3.style.height = `${prsentNum}%`
                        });
                    });
                }
            })
        }, options)

        const animateImg = (element, position) => {
            // element.style.height = `${position}px`
            if (window.innerWidth < 1024) {
                position -= 70;
            }
            // if (position < -30) {
            //     position = -30;
            // }
            element.style.transform = `translateY(${position}px)`
        }


        // карусель Эспрессо факты
        // Карусель блока Коллекции
        const swiperEspress = new Swiper('.espresso-slider', {
            direction: "horizontal",
            // loop: true,
            spaceBetween: 34,
            slidesPerView: "auto",
            navigation: {
                nextEl: '.slider-nav-arrows .slider-next-arrow',
                prevEl: '.slider-nav-arrows .slider-prev-arrow',
            },
            breakpoints: {
                1440: {
                    spaceBetween: 143,
                },
                768: {
                    spaceBetween: 75,
                }
            }
        });

        const espressoFacts = document.querySelectorAll('.espresso-block .espresso-slider .espresso-slide');

        const factsBlock = document.querySelector('.open-facts-block');
        const returnBtn = document.querySelector('.facts-header-btn');
        if (espressoFacts) {
            for (let i = 0; i < espressoFacts.length; i++) {
                espressoFacts[i].addEventListener('click', function (e) {
                    swiperFacts.slideTo(parseInt(this.dataset.slidenum));
                    bodyCont.classList.add('open-fact');
                    factsBlock.classList.add('facts-show')
                    if (window.innerWidth > 568)
                        if (openMenuBtnFacts)
                            openMenuBtnFacts.style.color = '#fff'
                    returnBtn.style.opacity = '1'
                })
            }
            returnBtn.querySelector('.return-btn').addEventListener('click', function (e) {
                factsBlock.classList.remove('facts-show')
                if (openMenuBtnFacts) {
                    openMenuBtnFacts.style = ''
                }
                returnBtn.style.opacity = '0'
                bodyCont.classList.remove('open-fact');
            })
        }


        const swiperFacts = new Swiper('.facts-slider', {
            direction: "horizontal",
            loop: true,
            slidesPerView: 1,
            effect: 'fade',
            speed: 1000,
            navigation: {
                nextEl: '.slider-next-arrow',
                prevEl: '.slider-prev-arrow',
            },

        });

        document.addEventListener('click', function (e) {
            if (!(!menuCont.classList.contains('open') || e.target === openMenuBtn)) {
                let menuClasses = ['nav-menu-body', 'nav-menu-header', 'menu-cont', 'menu-items', 'menu-header-title', 'select-menu-item'];
                if (!Array.from(e.target.classList).some((e) => menuClasses.includes(e))) {
                    menuCont.classList.remove('open');
                }
            }
            if (openMenuBtnFacts) {
                if (!(!menuContFacts.classList.contains('open') || e.target === openMenuBtnFacts)) {
                    let menuClasses = ['nav-menu-body', 'nav-menu-header', 'menu-cont', 'menu-items', 'menu-header-title', 'select-menu-item'];
                    if (!Array.from(e.target.classList).some((e) => menuClasses.includes(e))) {
                        menuContFacts.classList.remove('open');
                    }
                }
            }
        })

        const menuLinks = document.querySelectorAll('.nav-menu-item, .footer-menu-item');
        if (menuLinks) {
            menuLinks.forEach(navLink => {
                navLink.addEventListener('click', function (e) {
                    if (this.dataset.toscroll) {
                        let target = document.querySelector('#' + this.dataset.toscroll)
                        scroller.scrollTo(target);

                        factsBlock.classList.remove('facts-show')
                        bodyCont.classList.remove('open-fact');
                    }
                })
            })
        }

        // Модальное окно Купить
        const buyBtns = document.querySelectorAll('.buy-btn');
        // const buyModal = document.querySelector('.buy-modal');
        const closeModal = document.querySelectorAll('.modal-close');
        if (buyBtns) {
            buyBtns.forEach(byBtn => {
                byBtn.addEventListener('click', function (e) {
                    const buyModal = document.querySelector('[data-modal="' + this.dataset.selectmodal + '"]');
                    buyModal.classList.add('buy-modal-open');
                })
            })
            closeModal.forEach(clBtn => {
                clBtn.addEventListener('click', function (e) {
                    this.parentElement.classList.remove('buy-modal-open');
                })
            })
        }

        const marqueeContainers = document.querySelectorAll('.marquee-container');

        const observerMarqueeContainers = new IntersectionObserver((entries, observer) => {
            // для каждой записи-целевого элемента
            entries.forEach(entry => {
                // если элемент является наблюдаемым
                if (entry.isIntersecting) {
                    // console.log(entry);
                    entry.target.querySelector('.marquee').style = 'transform: translateX(0%);';
                }
            })
        }, options3)

        marqueeContainers.forEach(i => {
            observerMarqueeContainers.observe(i)
        })

        const modalClose2 = document.querySelector('.modal-close2');
        if (modalClose2) {
            modalClose2.addEventListener('click', function (e) {
                returnBtn.querySelector('.return-btn').click();
            });
        }

        if (window.innerWidth < 768) {
            const swiperMobCollection = new Swiper('.collection-scroll.swiper', {
                direction: "horizontal",
                loop: false,
                slidesPerView: 'auto',
                effect: 'slide',
                // spaceBetween: 15,
                draggable: true,
                freeMode: {
                    enabled: true,
                    sticky: true,
                },
                navigation: {
                    nextEl: '.collection-scroll .slider-next-arrow',
                    prevEl: '.collection-scroll .slider-prev-arrow',
                },

            });
        }
    })
})()